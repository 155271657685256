export default [
    "/login",
    "/sign-up",
    "/about-us-page",
    "/privacy-policy",
    "/forgot-password-page",
    "/terms-of-use",
    "/contact-us-page",
    "/faq-page",
    "/social-page/:shareId",
    "/verify-phone-number",
    "/confirm-signup-page",
    "/search-jobs",
    "/create-job-provider-page",
    "/all-jobs",
    "/blogs",
    "/blogs/:blog_title",
    "/location",
    "/location/:city",
    "/jobtitle",
    "/jobtitle/:title",
    "/category",
    "/category/:category",
    "/brands",
    "/brands/:brands",
    "/job/:jobLink",
    "/eazy-diner",
    "/royal-orchid-dubai",
    "/login-tjp-user",
    "/blog/unsubscribe/:email_id",
    "/the-plus-initiative",
    "/plus-initiative-enquiry",
    "/thank-you-page",
    "/candidate/:candidateLink",
    "/companies-page",
    "/jobprovider-jobs",
    "/activity-page",
    "/near-by-jobs",
    "/companies-list-page",
    "/jobseeker-applications",
    "/shortlisted-jobs",
    "/submit-resume-page",
    "/job-post-page/:postFlag",
    "/candidate-list-page/:flag",
    "/create-company-profile-page",
    "/archive-job-list",
    "/employer-home-page",
    "/dashboard",
    "/employer-reject-reason",
    "/shl-invite-assesment",
    "/shl-skill-import",
    "/create-metadata-page",
    "/admin-job-list-page",
    "/admin-candidate-list-page",
    "/admin-employer-list-page",
    "/create-blog",
    "/admin-employer-dashboard",
    "/admin-candidate-dashboard",
    "/users-role",
    "/admin-user-list",
    "/create-admin-user",
    "/jobs/",
    "/email-edm-list",
    "/create-email-edm",
    "/edm/:email_edm_id/:approve/:email_id",
    "/email-template-list",
    "/create-email-template",
    "/plus-initiative-enquiry"
]