import React, { Component } from 'react'
import AuthContext from "context/AuthProvider";
import { withRouter } from "react-router-dom";
import { FacebookIcon, LinkedinIcon } from "react-share";
import { INSTAGRAM_ICON, YOU_TUBE_ICON } from "assets/Constants/Icons.js";
import {TJP_FACEBOOK_LINK, TJP_LINKEDIN_LINK, TJP_INSTAGRAM_LINK, TJP_YOUTUBE_LINK} from "assets/Constants/Constants.js";
import { showLoadingScreen,hideLoadingScreen} from "components/LoadingScreen";

export class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userData : null, 
            isEmployee : true,
            quickSearchClass: "quick-search-div"        
        }
    }

    componentDidMount(){
        const sessionUserData = JSON.parse(this.context.getUserDetails());
        
        this.setState({
            userData : sessionUserData,
            isEmployee : sessionUserData ? (sessionUserData.profile === "employee" ? true : false) : false,
            isEmployer : sessionUserData ? (sessionUserData.profile === "employer" ? true :false) :false,
            quickSearchClass: sessionUserData ? (sessionUserData.profile === "employee" ? "" : "quick-search-div") : "quick-search-div",
         })
    }
    
    searchHandle = async (city) => {
        let regionValues = [city].join("_");
        window.location.href = `/search-jobs/null/${regionValues}`
    }

    showLoginPageHandler = (event) => {
        event.preventDefault();
        if(this.state.userData ===null){
            window.location.href = "/#login"
            window.indexComponent.showLoginPopup();    
        }
    }
    browseJobBtn = (event) => {
        event.preventDefault();
        showLoadingScreen();
        if(this.state.isEmployer){
            this.props.history.push("/jobprovider-jobs");
        }
        else{
            this.props.history.push("/all-jobs");
        }        
        hideLoadingScreen();
    }

    PostJobBtn = (event) => {
        event.preventDefault();
        if(this.state.userData !==null){
            if(this.state.isEmployer){
               this.props.history.push("/job-post-page/new-post");
            }
        }
        else{
            window.location.href ="/#login"
            window.indexComponent.showLoginPopup();
        }
        
    }
    popularCitiesBtn = (event) => {
        event.preventDefault();
        const popularCity = ["delhi","mumbai","pune","kolkata","chennai","bengaluru"];
        this.props.history.push({
            pathname:"/search-jobs",
            state:{
                keyword : "",
                region : popularCity,
            }
        });
        window.location.reload();
    }

    render() {
        return (
            <footer className="section footer-creative context-dark" id="tjp-footer">
                <div className="footer-creative-main">
                    <div className="container">
                        <div className="footer-div-container">
                            {/* <div className="col-3 col-lg-3 col-xl-3 font-rubik"></div> */}
                            <div className="font-rubik footer-section-1">
                                <p className="footer-font-16">The Job Plus</p>
                                {/* <div className="row row-narrow row-15 nav-list-post"> */}
                                    <div className="quick-search">
                                        <ul>
                                            <li>
                                                <a href="/pages/about-us-page.html">About</a>
                                            </li>
                                            
                                            <li>
                                                <a href="/contact-us-page">Contact us</a>
                                            </li>
                                            <li>
                                                <a href="/privacy-policy">Privacy Policy </a>
                                            </li>
                                            <li>
                                                <a href="/terms-of-use">Terms of Use</a>
                                            </li>
                                            <li>
                                                <a href="/faq-page">FAQ's </a>
                                            </li>
                                            {
                                            this.state.isEmployee ? (
                                            <div className="visibility-hidden">
                                                <li> <a href="!#"> </a>A</li>
                                            </div>
                                            ):(<></>)
                                            }
                                        </ul>
                                    </div>
                                {/* </div> */}
                            </div>
                    
                            <div className="font-rubik footer-section-2">
                                <p className="footer-font-16">Browse Jobs</p>
                                    <div className="quick-search footer-browse-job-div">
                                        <ul className="footer-browse-li">
                                            <li className="padding-bottom-5">
                                                <a href="/category">Browse by Job Category</a>
                                            </li>
                                            <li className="padding-bottom-5">
                                                <a href="/jobtitle">Browse by Job Title</a>
                                            </li>
                                            <li className="padding-bottom-5">
                                                <a href="/location">Browse by Job Location</a>
                                            </li>
                                            <li className="padding-bottom-5">
                                                <a href="/brands">Browse by Brand</a>
                                            </li>
                                            
                                        </ul>
                                        <div className="visibility-hidden">
                                                <li> <a href="!#"> </a>A</li>
                                        </div>
                                        {
                                            this.state.isEmployee && (
                                            <div className="visibility-hidden">
                                                <li> <a href="!#"> </a>A</li>
                                            </div>
                                            ) 
                                        }
                                    </div>
                            </div>
                               
                            <div className="font-rubik footer-section-2 mt-0">
                                <p className="footer-font-16">Quick Search</p>
                                    <div className={`quick-search ${this.state.quickSearchClass}`}>
                                        <ul >
                                            <li>
                                                <a href="!#" onClick={this.popularCitiesBtn}>Popular cities</a>
                                            </li>
                                            <li>
                                                    <a href="!#" onClick={this.browseJobBtn}>Browse jobs</a>
                                            </li>
                                            <li> 
                                                { 
                                                  !this.state.isEmployee ? (
                                                    <>
                                                    <a href="!#" onClick={this.PostJobBtn}>Post a job</a>
                                                    </>
                                                  ):(
                                                    <></>
                                                  )
                                                }
                                            </li>
                                            
                                            { this.state.isEmployee ? (
                                                <>
                                                    <li>
                                                        <a href="/submit-resume-page">Submit Resume</a>
                                                    </li>
                                                    <li>
                                                        <a href="/companies-list-page">Companies</a>
                                                    </li>
                                                </>
                                                ):(
                                                    <></>
                                                )
                                            }
                                        </ul>
                                    </div>
                                    {/* <div className="col-4">
                                        <ul>
                                        </ul>
                                    </div> */}
                                    <div className="quick-search-div">
                                        <ul className="list-inline list-inline-xs list-inline-footer">
                                            <li title="Facebook">
                                                <a href={TJP_FACEBOOK_LINK} target="_blank" rel="noopener noreferrer nofollow" >
                                                    <FacebookIcon className="icon icon-xxs icon-filled icon-filled-brand icon-circle" />
                                                </a>
                                            </li>
                                            <li title="LinkedIn">
                                                <a href={TJP_LINKEDIN_LINK} target="_blank" rel="noopener noreferrer nofollow" >
                                                    <LinkedinIcon className="icon icon-xxs icon-filled icon-filled-brand icon-circle" />
                                                </a>
                                            </li>
                                            <li title="Instagram">
                                                <a href={TJP_INSTAGRAM_LINK}  target="_blank" rel="noopener noreferrer nofollow" >
                                                    <i className={INSTAGRAM_ICON} />
                                                </a>
                                            </li>
                                            <li title="Youtube">
                                                <a href={TJP_YOUTUBE_LINK} target="_blank" rel="noopener noreferrer nofollow"  >
                                                    <i className={`${YOU_TUBE_ICON} youtube-icon`} />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                            </div>
                            {/* <div className="col-3 col-lg-3 col-xl-3 font-rubik"></div> */}
                            {/* <div className="col-lg-5 col-xl-4 fast-access-div">
                                <p className="footer-font-16">Fast access</p>
                                <div className="footer-btn-div">
                                    <div>
                                       <ul>
                                            <input className="footer-signin-btn footer-sign-btn-pos padding-left-15px text-black text-placeholder font-rubik text-align-left" id="footerSearch" name="footerSearch" type="text" placeholder="QUESTIONS ?" />
                                        </ul> 
                                    </div>
                                    <div>
                                        <ul>
                                            <li><button className="footer-search-button text-white font-rubik footer-search-btn-pos footer-search-btn-responsive"> SEARCH</button></li>
                                            
                                        </ul>
                                    </div>
                                    <div className="col-2 col-sm-1"></div>
                                </div>
                            </div> */}
                    </div>
                </div>
            </div>
            <div className="footer-creative-aside">
                <div className="container">
                    <p className="rights">
                        <label className="footer-text">[ © 2021-2022 The Job Plus ]      [ v1.0 ]      [ ® All Rights Reserved ]</label>
                    </p>
                </div>
            </div>
            </footer>
        );
    }
}

Footer.contextType = AuthContext;
 export default withRouter(Footer);
