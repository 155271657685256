import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import AuthContext from 'context/AuthProvider';
import { TOAST_WARN } from "assets/Constants/Constants";
import { ShowAlert } from "lib/ShowToast";

const CandidateRoutes = ({ component: Component, ...rest }) => {
    const authContext = useContext(AuthContext);
    localStorage.setItem("lastVisitedPage", rest.path);
    const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
    const sessionUserData = JSON.parse(authContext.getUserDetails());
    if(sessionUserData){
        return (
            <Route
                {...rest}
                render={props => {
                    if (isAuthenticated && sessionUserData.profile === "employee") {
                        return <Component {...props} />;
                    }
                    else if (sessionUserData.profile === "admin") {
                        return (
                            <Redirect
                                to={{
                                    pathname: "/admin-panel",
                                    state: {
                                        from: props.location
                                    }
                                }}
                            />
                        );
                    }
                    else {
                        authContext.signOut();
                        return (
                            <Redirect
                                to={{
                                    pathname: "/",
                                    state: {
                                        from: props.location
                                    }
                                }}
                            />
                        );
                    }
                }}
            />
        );
    }
    else{
        if(rest.path === "/all-jobs"){
            return (
                <Route 
                    {...rest}
                    render={props => {
                        return <Component {...props} />;
                    }}
                />
            )
        }
        else{
            localStorage.setItem("lastVisitedPage", rest.path);
            authContext.signOut();
            if(rest.path === "/jobseeker-applications"){
                ShowAlert("Please login to check your applications", TOAST_WARN, {
                    autoClose: true
                });
            }
            return (
                <Route 
                    {...rest}
                    render={props => {
                        return (
                            <Redirect
                                to={{
                                    pathname: "/",
                                    hash: "login",
                                    state: {
                                        from: props.location
                                    }
                                }}
                            />
                        )
                    }}
                />
            );
        }
    }
};

export default CandidateRoutes;