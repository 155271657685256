import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import AuthContext from 'context/AuthProvider';

const GenericRoutes = ({ component: Component, ...rest }) => {
    const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
    const authContext = useContext(AuthContext);
    const sessionUserData = JSON.parse(authContext.getUserDetails());
    const isFromSocial = localStorage.getItem("isFromSocial")  === "true";

    if(isFromSocial){
        localStorage.setItem("isFromSocial", false);
        return (
            <Route
                {...rest}
                render={props => {
                    return <Component {...props} />;
                }}
            />
        )
    }
    else if (sessionUserData){
        return (
            <Route
                {...rest}
                render={props => {
                    if (isAuthenticated) {
                        return <Component {...props} />;
                    }
                    else if (sessionUserData.profile === "admin") {
                        return (
                            <Redirect
                                to={{
                                    pathname: "/admin-panel",
                                    state: {
                                        from: props.location
                                    }
                                }}
                            />
                        );
                    }
                    else {
                        return (
                            <Redirect
                                to={{
                                    pathname: "/",
                                    state: {
                                        from: props.location
                                    }
                                }}
                            />
                        );
                    }
                }}
            />
        );
    }
    else{
        if (rest.path !== "/companies-page") {
            authContext.signOut();
            return (
                <Route
                    {...rest}
                    render={props => {
                        return (
                            <Redirect
                                to={{
                                    pathname: "/",
                                    state: {
                                        from: props.location
                                    }
                                }}
                            />
                        )
                    }}
                />
            );
        }
    }
};

export default GenericRoutes;