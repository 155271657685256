export const CAMPAIGN_ICON      = "fa fa-trello";
export const EMAIL_ICON         = "fa fa-envelope";
export const EDIT_ICON          = "fa fa-edit";
export const DELETE_ICON        = "fa fa-trash-o";
export const CALENDER_ICON      = "fa fa-calendar";
export const MOBILE_ICON        = "fa fa-mobile";
export const USERS_ICON         = "fa fa-users";
export const USER_ICON          = "fa fa-user-circle-o";
export const GOOGLE_ICON        = "fa fa-google";
export const TWITTER_ICON       = "fa fa-twitter";
export const FACEBOOK_ICON      = "fa fa-facebook";
export const SIGN_OUT           = "fa fa-sign-out";
export const CROSS_ICON         = "fa fa-times-circle";
export const TRELLO_ICON        = "fa fa-trello";
export const CLOSE_ICON         = "fa fa-times-circle-o";
export const EYE_ICON           = "fa fa-eye";
export const EYE_ICON_SLASH     = "fa fa-eye-slash";
export const GOOGLE_PLUS_ICON   = "fa fa-google-plus";
export const PINTREST_ICON      = "a fa-pinterest-p"; 
export const DOWNLOAD_ICON      = "fas fa-download";
export const TICK_ICON          = "fa fa-check";
export const SEARCH_ICON        = "fa fa-search";
export const HOTEL_ICON         = "fa fa-h-square";
export const FILE_TEXT_ICON     = "fa fa-file-text";
export const UL_CHECKLIST_ICON  = "fas fa-tasks";
export const LINE_CHART_ICON    = "fa fa-line-chart";
export const PLUS_ICON          = "fa fa-plus";
export const LIST_ICON          = "fa fa-list";
export const INFO_ICON          = "fa fa-info-circle";
export const PHONE_ICON         = "fa fa-phone-square";
export const QUESTION_ICON      = "fa fa-question-circle";
export const USER_SECRET_ICON   = "fa fa-user-secret";
export const NUMBER_LIST_ICON   = "fa fa-list-ol";
export const INSTITUTE_ICON     = "fa fa-university";
export const SEND_ICON          = "fa fa-paper-plane";
export const BRIEFCASE          = "fa fa-briefcase";
export const CIRCLE_CHECK       = "fa fa-check-circle";
export const CHEVRON_UP         = "fa fa-chevron-up";
export const UPLOAD_FILE        = "fa fa-cloud-upload";
export const SLIDER_ICON        = "fa fa-sliders";
export const ADD_USER           = "fa fa-user-plus";
export const ASC_ICON           = "fa fa-sort-numeric-desc";
export const DSC_ICON           = "fa fa-sort-numeric-asc";
export const DASHBOARD_ICON     = "fa fa-dashboard";
export const CHEVRON_RIGHT      = "fa fa-chevron-right";
export const INSTAGRAM_ICON     = "fa fa-instagram";
export const PIE_CHART_ICON     = "fa fa-pie-chart";
export const USER_ROLE          = "fa fa fa-tags";
export const ADMIN_USERS        = "fa fa fa-sitemap";
export const YOU_TUBE_ICON      = "fa fa-youtube-play";
export const EDUCATION_ICON     = "fa fa-graduation-cap";