import React from "react";
import ReactDOM from "react-dom";
import Amplify from "aws-amplify";
import { AuthProvider } from "context/AuthProvider";
import { BrowserRouter } from "react-router-dom";
// import { BrowserRouter, Route as StaticRoutes } from 'react-router-dom';
// styles
import "assets/css/bootstrap.css";
import "assets/css/style.css";
import "assets/css/fonts.css";
import "assets/scss/job-plus.scss";

// Pages
import { Routes } from "components/RoutesComponent/Routes";
import config from "./config";
import TostComponent from "components/TostComponent";
import Footer from "components/Footer";
import ErrorBoundary from "components/ErrorBoundary";
const IndexNavbar = React.lazy(() => import("components/Navbars/IndexNavbar"));
const lastVisitedPage = localStorage.getItem("lastVisitedPage");
const sessionUserData = JSON.parse(localStorage.getItem("userDetails"));
localStorage.setItem("lastVisitedPage", lastVisitedPage ? lastVisitedPage : null);
// others
Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  oauth: {
    domain: config.OUTH_DOMAIN_URL,
    scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin"
    ],
    redirectSignIn: config.SOCIAL_SIGN_IN_REDIRECT_URL,
    redirectSignOut: config.SOCIAL_SIGN_OUT_REDIRECT_URL,
    responseType: "code"
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: "jobproviders",
        endpoint: config.apiGateway.URL + "jobproviders",
        region: config.apiGateway.REGION,
      },
      {
        name: "jobseekers",
        endpoint: config.apiGateway.URL + "jobseekers",
        region: config.apiGateway.REGION,
      },
      {
        name: "transaction",
        endpoint: config.apiGateway.URL + "transaction",
        region: config.apiGateway.REGION,
      },
      {
        name: "metadata",
        endpoint: config.apiGateway.URL + "metadata",
        region: config.apiGateway.REGION,
      },
      {
        name: "jobs",
        endpoint: config.apiGateway.JOB_URL + "jobs",
        region: config.apiGateway.REGION,
      },
      {
        name: "jobapplications",
        endpoint: config.apiGateway.URL + "jobapplications",
        region: config.apiGateway.REGION,
      },
      {
        name: "subscription",
        endpoint: config.apiGateway.URL + "subscription",
        region: config.apiGateway.REGION,
      },
      {
        name: "profile",
        endpoint: config.apiGateway.URL + "profile",
        region: config.apiGateway.REGION,
      },
      {
        name: "jobshortlist",
        endpoint: config.apiGateway.URL + "jobshortlist",
        region: config.apiGateway.REGION,
      },
      {
        name: "candidateshortlist",
        endpoint: config.apiGateway.URL + "candidateshortlist",
        region: config.apiGateway.REGION,
      },
      {
        name: "applicationcandidatestatus",
        endpoint: config.apiGateway.URL + "applicationcandidatestatus",
        region: config.apiGateway.REGION,
      },
      {
        name: "candidatejobstatus",
        endpoint: config.apiGateway.URL + "candidatejobstatus",
        region: config.apiGateway.REGION,
      },
      {
        name: "activity",
        endpoint: config.apiGateway.ACTIVITY_URL + "activity",
        region: config.apiGateway.REGION,
      },
      {
        name: "sharesocial",
        endpoint: config.apiGateway.URL + "sharesocial",
        region: config.apiGateway.REGION,
      },
      {
        name: "shl",
        endpoint: config.apiGateway.URL + "shl",
        region: config.apiGateway.REGION,
      },
      {
        name: "communication",
        endpoint: config.apiGateway.URL + "communication",
        region: config.apiGateway.REGION,
      },
      {
        name: "dashboard",
        endpoint: config.apiGateway.URL + "dashboard",
        region: config.apiGateway.REGION,
      },
      {
        name: "blogs",
        endpoint: config.apiGateway.BLOG_URL + "blogs",
        region: config.apiGateway.REGION,
      },
      {
        name: "roles",
        endpoint: config.apiGateway.ROLE_URL + "roles",
        region: config.apiGateway.REGION,
      },
      {
        name: "users",
        endpoint: config.apiGateway.ROLE_URL + "users",
        region: config.apiGateway.REGION,
      },
      {
        name: "blogcomments",
        endpoint: config.apiGateway.BLOG_URL + "blogcomments",
        region: config.apiGateway.REGION,
      },
      {
        name: "blogsubscriptions",
        endpoint: config.apiGateway.BLOG_URL + "blogsubscriptions",
        region: config.apiGateway.REGION,
      },
      {
        name: "plusinitiative",
        endpoint: config.apiGateway.JOB_URL + "plusinitiative",
        region: config.apiGateway.REGION,
      },
      {
        name: "emailedm",
        endpoint: config.apiGateway.BLOG_URL + "emailedm",
        region: config.apiGateway.REGION,
      },
      {
        name: "emailtemplate",
        endpoint: config.apiGateway.BLOG_URL + "emailtemplate",
        region: config.apiGateway.REGION,
      }
    ],
  },
});
let pageRef = React.createRef();
const path = window.location.pathname;

const isCandidateProfile = sessionUserData ? (sessionUserData.profile === "employee" ? true : false) : false;
const isEmployerProfile = sessionUserData ? (sessionUserData.profile === "employer" ? true : false) : false;
const isAdminProfile = sessionUserData ? ((sessionUserData.profile === "admin" || sessionUserData.profile === "tjp_user") ? true : false) : false;


if(!sessionUserData){
  if(path === "/"){
    window.location.href = "/pages/home.html";
  }
}
else{
  if(path === "/"){
    if(isCandidateProfile){
      window.location.href = "/pages/home.html";
    }
    else if(isEmployerProfile){
      window.location.href = "/employer-home-page";
    }
    else if(isAdminProfile){
      if(sessionUserData.role_name){
        if(sessionUserData.role_name === "admin"){
          window.location.href = "/admin-employer-dashboard";
        }
        else if(sessionUserData.role_name === "job creator"){
          window.location.href = "/admin-job-list-page";
        }
        else{
          window.location.href = "/blogs";
        }
      }
    }
  }
}

ReactDOM.render(
  <>
      
          <ErrorBoundary>
            <AuthProvider>
              <React.Suspense fallback={<div></div>}>
                <BrowserRouter>
                  <IndexNavbar pageReference={pageRef} />
                  <TostComponent />
                  <Routes pageRef={pageRef} />
                  <Footer />
                </BrowserRouter>
              </React.Suspense>
            </AuthProvider>
          </ErrorBoundary>
       
  </>,
  document.getElementById("root")
);
