import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import AuthContext from 'context/AuthProvider';
import { hasRoutePermission } from "lib/Permission";

const RecruiterRoutes = ({ component: Component, ...rest }) => {
    const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
    const authContext = useContext(AuthContext);
    const sessionUserData = JSON.parse(authContext.getUserDetails());
    let permissionFlag = false;
    if(sessionUserData){
        if(sessionUserData.profile === "admin" || sessionUserData.profile === "tjp_user"){
            permissionFlag = hasRoutePermission(rest.path);
        }
    }
    if(sessionUserData){
        return (
            <Route
                {...rest}
                render={props => {
                    if (isAuthenticated && sessionUserData.profile === "employer") {
                        return <Component {...props} />;
                    }
                    else if (sessionUserData.profile === "admin" || sessionUserData.profile === "tjp_user") {
                        if (permissionFlag) {
                            return <Component {...props} />;
                        }
                        else {
                            return (
                                <Redirect
                                    to={{
                                        pathname: "/",
                                        state: {
                                            from: props.location
                                        }
                                    }}
                                />
                            );
                        }
                    }
                    else {
                        return (
                            <Redirect
                                to={{
                                    pathname: "/employer-home-page",
                                    state: {
                                        from: props.location
                                    }
                                }}
                            />
                        );
                    }
                }}
            />
        );
    }
    else{
        authContext.signOut();
        return (
            <Route 
                {...rest}
                render={props => {
                    return (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: {
                                    from: props.location
                                }
                            }}
                        />
                    )
                }}
            />
        );
    }
};

export default RecruiterRoutes;
