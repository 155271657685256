import React, { useState, useEffect } from 'react'
import { API, Auth, Hub } from "aws-amplify";
import { ShowAlert } from "lib/ShowToast";
import { TOAST_SUCCESS, TOAST_ERROR } from "assets/Constants/Constants";
import { showLoadingScreen, hideLoadingScreen } from 'components/LoadingScreen';
import Cookies from 'universal-cookie';
import { getPermissionMatrix } from "lib/Permission";

const AuthContext = React.createContext();
const cookies = new Cookies();

export function AuthProvider(props) {
    const [userData, setUserData] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        Hub.listen("auth", ({ payload: { event, data } }) => {
            switch (event) {
                case "signIn":
                    showLoadingScreen();
                    console.log("Sign IN");
                    if(!isAuthenticated){
                        localStorage.setItem("isFetchUserData", false);
                        setUserData(data);
                        getCurrentUser("From Sign In");
                        setIsAuthenticated(true);
                        localStorage.setItem("isAuthenticated", true);
                    }
                    break;
                case "signOut":
                    console.log("Sign OUT");
                    const lastVisitedPage = localStorage.getItem("lastVisitedPage");
                    setIsAuthenticated(false);
                    localStorage.setItem("isAuthenticated", false);
                    localStorage.removeItem("userDetails");
                    localStorage.removeItem("jobSearchList", []);
                    localStorage.clear();
                    localStorage.setItem("lastVisitedPage", lastVisitedPage);
                    break;
                case "customOAuthState":
                    console.log("Custom OAuth State");
                    getCurrentUser("From Custome State");
                    break;
                default:
                    break;
            }
        });
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        Auth.currentSession()
            .then((session) => {
                setIsAuthenticated(true);
                localStorage.setItem("isAuthenticated", true);
                var idTokenExpire = session.getIdToken().getExpiration();
                var refreshToken = session.getRefreshToken();
                var currentTimeSeconds = Math.round(+new Date() / 1000);
                if (idTokenExpire < currentTimeSeconds) {
                    Auth.currentAuthenticatedUser()
                        .then((res) => {
                            res.refreshSession(refreshToken, async (err, data) => {
                                if (err) {
                                    await signOut();
                                }
                            });
                        });
                }
            })
            .catch(async (e) => {
                setIsAuthenticated(false);
                localStorage.setItem("isAuthenticated", false);
                await signOut();
                console.log("Exception while fetching session: ", e);
            });
    }, []);

    const getCurrentUser = () => {
        showLoadingScreen();
        Auth.currentAuthenticatedUser()
        .then(async (user) => {
            if(!isAuthenticated){

                console.log("From Auth: ", user);
                if (localStorage.getItem('userDetails')) {
                    if (!isUserSessionActive) {
                        Auth.currentSession()
                            .then(data => console.log("Refresh Session", data))
                            .catch(err => console.log("Refresh Session", err));
                    }
                }
                let userData = null;
                if (user.attributes.phone_number) {
                    userData = await getProfileDetails(user.attributes.phone_number, true)
                }
                else {
                    userData = await getProfileDetails(user.attributes.email, false)
                }
                // const userData = await getProfileDetails(user.attributes.username)
                console.log("Login Type: ", localStorage.getItem("loginType"));
                if (localStorage.getItem("loginType") === "social") {
                    Auth.updateUserAttributes(user, {
                        profile: localStorage.getItem("profileChoice")
                    })
                    .then(async(data) =>{
                        if (localStorage.getItem("signUpType") === "socialSignUp") {
                            if (Object.keys(userData.responseResult).length === 0 && userData.responseResult.constructor === Object) {
                                const candidateResponse = await addSeekerFromSocialLogin(user);
                                setUserDetails({ ...user.attributes, ...candidateResponse });
                                hideLoadingScreen();
                                ShowAlert("Your are registered successfully.", TOAST_SUCCESS, {
                                    autoClose: true,
                                    onClose: () => {
                                        const lastVisitedPage = localStorage.getItem("lastVisitedPage");
                                        if(lastVisitedPage && lastVisitedPage !== "null"){
                                            window.location.href = `${lastVisitedPage}`;
                                        }
                                        else{
                                            window.location.href = "/pages/home.html";
                                        }
                                    }
                                });
                            }
                            else {
                                ShowAlert("Given email id already associcated with another account. Please choose another email id or click on login", TOAST_SUCCESS, null);
                                hideLoadingScreen();
                            }
                        }
                        else {
                            if (Object.keys(userData.responseResult).length === 0 && userData.responseResult.constructor === Object) {
                                const candidateResponse = await addSeekerFromSocialLogin(user);
                                console.log(candidateResponse);
                                setUserDetails({ ...user.attributes, ...candidateResponse });
                                const lastVisitedPage = localStorage.getItem("lastVisitedPage");
                                if(lastVisitedPage && lastVisitedPage !== "null"){
                                    if(user.attributes.profile === "employee"){
                                        window.location.href = `${lastVisitedPage}`;
                                    }
                                    else{
                                        window.location.href = "/employer-home-page";
                                    }
                                }
                                else{
                                    window.location.href = "/";
                                }
                                hideLoadingScreen();
                            }
                            else{
                                setUserDetails({ ...user.attributes, ...userData.responseResult });
                                const lastVisitedPage = localStorage.getItem("lastVisitedPage");
                                if(lastVisitedPage && lastVisitedPage !== "null"){
                                    if(user.attributes.profile === "employee"){
                                        window.location.href = `${lastVisitedPage}`;
                                    }
                                    else if (user.attributes.profile === "employer") {
                                        window.location.href = "/employer-home-page";    
                                    }
                                    else {
                                        window.location.href = "/";
                                    }
                                }
                                else{
                                    window.location.href = "/";
                                }
                                hideLoadingScreen();
                            }
                        }
    
                        localStorage.removeItem("profileChoice");
                        localStorage.removeItem("loginType");
                        localStorage.removeItem("signUpType");
                    })
                    .catch((error)=>{
                        console.log("Error While social signup",error);
                    });
                    
                }
                else {
                    setUserDetails({ ...user.attributes, ...userData.responseResult });
                    localStorage.removeItem("loginType");
                    localStorage.removeItem("signUpType");
                    hideLoadingScreen();
                }
            }
        })
        .catch((error) => {
            hideLoadingScreen();
            console.log("Not signed in", error);
            localStorage.removeItem("signUpType");
        });
    }

    const getMetadata = async () => {
        const metadataResponse = await API.get("metadata", "");
        localStorage.setItem("metadata", JSON.stringify(metadataResponse));
    }

    const signIn = async (loginCredentials) => {
        try {
            getMetadata();
            let user = await Auth.signIn(loginCredentials.userName, loginCredentials.password);
            getPermissionMatrix();
            setIsAuthenticated(true);
            localStorage.setItem("isAuthenticated", true);
            console.log("User Sign Data", user.attributes);
            localStorage.setItem("jobSearchList", []);
            if (user.attributes.profile === "admin") {
                setUserDetails({ ...user.attributes })
                localStorage.removeItem('userRole');
                localStorage.setItem("userRole", "admin");
                setTimeout(() =>{
                    window.location.href = "/admin-employer-dashboard";
                },1000);
            } else if (user.attributes.profile === "tjp_user") {
                setUserDetails({ ...user.attributes })
                if(localStorage.getItem("isFromAdmin") ==="false" || localStorage.getItem("isFromAdmin") === null){
                    await signOut();
                    ShowAlert("Your profile is of the TJP user, you need to login with the appropriate page.", TOAST_ERROR, {
                        autoClose: true,
                        onClose: () => {
                           window.location.href="/login-tjp-user";
                        }
                    });
                }
                else{
                    if(localStorage.getItem('userRole') === "job creator"){
                        window.location.href = "/admin-job-list-page";
                    }
                    else{
                        window.location.href = "/blogs";
                    }
                }
            }
            else {
                localStorage.setItem("isFetchUserData", false);
                const lastVisitedPage = localStorage.getItem("lastVisitedPage");
                localStorage.removeItem("lastVisitedPage");
                let userDetails = localStorage.getItem("userDetails");
                setInterval(() => {
                    userDetails = localStorage.getItem("userDetails");
                    if(userDetails){
                        clearInterval(userDetails);
                        if(lastVisitedPage && lastVisitedPage !== "null"){
                            if(user.attributes.profile === "employee"){
                                
                                window.location.href = `${lastVisitedPage}`;
                            }
                            else{
                                window.location.href = "/";
                            }
                        }
                        else{
                            if(user.attributes.profile === "employer"){
                                window.location.href = "/employer-home-page";
                            }else{
                                window.location.href = "/";
                            }
                        }
                    }
                }, 500);
                
            }
        }
        catch (error) {
            console.log(error);
            localStorage.setItem("isFromAdmin", false);
            localStorage.removeItem('userRole');
            if(error.message === "User is not confirmed.") {
                let candidateData = {
                    email: "",
                    mobile_no: "",
                    fromVerification: true
                }
                if (loginCredentials.isPhoneNumber) {
                    candidateData.mobile_no = loginCredentials.userName
                    return candidateData;
                } else {
                    candidateData.email = loginCredentials.userName
                    return candidateData;
                }
                // let loginValue = loginCredentials.userName;
                // window.location.href = "/confirm-signup-page?userName="+loginValue;
            }
            else if(error.message === "User is disabled."){
                ShowAlert("This profile is not approved. Please contact The Job Plus team.", TOAST_ERROR, null);
            } 
            else if(error.message ==="Pending sign-in attempt already in progress"){
                console.log(error.message);
            }
            else{
                ShowAlert(error.message, TOAST_ERROR, null);
            }
        }
    }

    const signOut = async () => {
        const lastVisitedPage = localStorage.getItem("lastVisitedPage");
        try {
            await Auth.signOut({ global: true });
            setIsAuthenticated(false);
            localStorage.setItem("isAuthenticated", false);
            localStorage.removeItem("userDetails");
            localStorage.removeItem("jobSearchList", []);
            localStorage.setItem('isFetchUserData', false);
            localStorage.setItem('activityCount', null);
            localStorage.clear();
            localStorage.setItem("lastVisitedPage", lastVisitedPage);
        } catch (error) {
            console.log('error signing out: ', error);
            setIsAuthenticated(false);
            localStorage.setItem("isAuthenticated", false);
            localStorage.removeItem("userDetails");
            localStorage.removeItem("jobSearchList", []);
            localStorage.setItem('isFetchUserData', false);
            localStorage.setItem('activityCount', null);
            localStorage.clear();
            localStorage.setItem("lastVisitedPage", lastVisitedPage);
        }
    }

    const setUserDetails = (userData) => {
        localStorage.setItem('userDetails', JSON.stringify(userData));
    }

    const getUserDetails = () => {
        const userDetails = localStorage.getItem("userDetails");
        localStorage.setItem('userDetails', userDetails);
        if (userDetails) {
            return userDetails;
        }
        else {
            return null;
        }
    }

    const getProfileDetails = async (emailOrPhone, isPhoneNumber) => {
        if (localStorage.getItem("isFromAdmin") !== "false" && localStorage.getItem("isFromAdmin") !== null) {
            return await API.get("users", "/email/" + emailOrPhone.toLowerCase());
        }
        else if (isPhoneNumber) {
            return await API.get("profile", "/phone/" + emailOrPhone);
        }
        else {
            return await API.get("profile", "/email/" + emailOrPhone.toLowerCase());
        }

    }

    const reLoginUser = () => {
        Auth.currentAuthenticatedUser()
            .then(async (user) => {
                const userData = await getProfileDetails(user.attributes.email)
                setUserDetails({ ...user.attributes, ...userData.responseResult })
            })
            .catch((error) => {
                console.log("Not signed in", error);
            });
    }

    const addSeekerFromSocialLogin = async (user) => {
        let firstName= user.attributes.name.toLowerCase();
        const lastName = user.attributes.family_name.toLowerCase();
        const result = firstName.endsWith(lastName);
        if(result === true){
            const lastIndexOfSpace = firstName.lastIndexOf(' ');
            firstName = firstName.substring(0, lastIndexOfSpace);
        }
        const sourceCookieParams = cookies.get('sourceParams');
        let sourceParams = {};
        if (!sourceCookieParams) {
            sourceParams = {
                seeker_source: "TJP",
                medium: "unknown",
                campaign: "unknown",
                fbclid: "unknown",
                utm_term: "unknown"
            };
        }
        else {
            sourceParams = sourceCookieParams;
        }

        let jobSeekerData = {
            first_name: firstName,
            last_name: lastName,
            mobile_no: "",
            email: user.attributes.email,
            candidate_photo: user.attributes.picture,
            is_logged_with_mobile: false,
            is_login_attempt: false,
            is_social_login: true,
            ...sourceParams
        };
        cookies.remove('sourceParams');
        return await API.post("jobseekers", "", { body: jobSeekerData });
    }

    const isUserSessionActive = async () => {
        try {
            return await Auth.currentAuthenticatedUser();
        } catch (error) {
            return null
        }
    }

    const getCurrentSession = () => {
        Auth.currentSession()
        .then(data => console.log("Current Session", data))
        .catch(err => console.log("Current Session error", err));
    }

    const refreshUserSession = async () => {
        try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            const currentSession = await Auth.currentSession();
            cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
              console.log('session', err, session);
              // do whatever you want to do now :)
            });
          } catch (e) {
            console.log('Unable to refresh Token', e);
          }
    }

    return (
        <AuthContext.Provider value={{ isAuthenticated, userData, signIn, reLoginUser, getUserDetails, signOut, isUserSessionActive, getProfileDetails, getMetadata, getCurrentUser, getCurrentSession, refreshUserSession }}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContext
