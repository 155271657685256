import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import AuthContext from 'context/AuthProvider';

const PublicRoutes = ({ component: Component, ...rest }) => {
    const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
    const authContext = useContext(AuthContext);
    if(isAuthenticated){
        return (
            <Route
                {...rest}
                render={props => {
                    if (isAuthenticated) {
                        const sessionUserData = JSON.parse(authContext.getUserDetails());
                        if (sessionUserData) {
                            if (sessionUserData.profile === "employer") {
                                if(rest.path === "/"){
                                    return (
                                        <Redirect
                                            to={{
                                                pathname: "/employer-home-page",
                                                state: {
                                                    from: props.location
                                                }
                                            }}  
                                        />
                                    );
                                }
                                else{
                                    return <Component {...props} />
                                }
                            } 
                            else {
                                return <Component {...props} />
                            }                        
                        }
                        else {
                            return <Component {...props} />
                        }
                    }
                    else{
                        return <Component {...props} />
                    }
                }}
            />
        );
    }
    else{
        return (
            <Route
                {...rest}
                render={props => {
                    return <Component {...props} />
                }}
            />
        );
    }
};

export default PublicRoutes;