export const S3_BUCKET_URL      = (process.env.REACT_APP_ENV === "dev" || process.env.REACT_APP_ENV === "local") ? "https://dev-jobplus-webapp.s3.amazonaws.com/public/" : "https://prod-jobplus-webapp.s3.amazonaws.com/public/";
export const hostURL = window.location.protocol+'//'+window.location.hostname+(window.location.port ? ':'+window.location.port: '');
export const INITIATE_PAYMENT_URL = "https://securegw-stage.paytm.in/theia/api/v1/initiateTransaction";
export const PAYMRNT_PROCESS_URL = "https://securegw-stage.paytm.in/theia/api/v1/showPaymentPage";
export const PAYTM_PAYMENT_STATUS_HOST = "securegw-stage.paytm.in";
export const PAYTM_PAYMENT_STATUS_PATH = "/v3/order/status";
export const DEFAULT_PAGE_SIZE = 10;
export const JOB_LIST_MODEL = "JobList";
export const SHORTLIST_MODEL = "JobShortlist";
export const APPLIED_JOBS_MODEL = "JobApplicationlist";
export const JOB_PROVIDER_JOBS = "jobProviderJobs";
export const SHORTLIST_CANDIDATE = "SHORTLIST_CANDIDATE";
export const APPLIED_CANDIDATE = "APPLIED_CANDIDATE";
export const GOOGLE_MAP_API_KEY = "AIzaSyDJf4tLjEQDAdgQrlfrHoQm1LQUawPSMYY";
export const check_String = /^[a-zA-Z]+$/;// New Changes
export const emailPattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
);
export const numberPattern = new RegExp(
    /^(\+\d{1,3}[- ]?)?\d{10}$/
);
export const PIN_CODE_PATTERN = new RegExp(
    /^[1-9][0-9]{5}$/
);
export const PHONE_NUMBER_PATTERN = new RegExp(
    /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[56789]\d{9}$/ //eslint-disable-line
);
// export const passwordMatchPattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{9,}$/); 
// eslint-disable-next-line
export const passwordMatchPattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()--+={}\[\]\\:;"'<>,.?/_₹])[A-Za-z\d~`!@#$%^&*()--+={}\[\]\\:;"'<>,.?/_₹]{9,}$/);
export const TOAST_INFO = "INFO";
export const TOAST_WARN = "WARN";
export const TOAST_SUCCESS = "SUCCESS";
export const TOAST_ERROR = "ERROR";
export const TINY_MCE_API_KEY = "il88igc9hhtd2ibsoj9u2jc03ccxxbonpxj68e6nq2wnzfr2"; //currenty deployed on prod 14st Aug
export const FROM_MAIL_ID = "Job Plus Communication <communication@thejobplus.com>";
export const Hotel_ID ="hotels & resorts";
export const FoodandB_ID = "food and beverage (restaurant & qsr)";
export const FacilityManagement_ID = "facilities management";

export const SELECT_FILTER_STYLES = {
    option: (provided, state) => ({
        ...provided,
        color: "#151515"
    }),
    control: (base, state) => ({
        ...base,
        minHeight: "60px",
        // height: "60px",
        borderColor: "rgb(150, 145, 171)",
        '&:hover': {
            border: '1px solid rgb(150, 145, 171) '
        },
        color: "#151515"
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: 'grey',
            fontStyle: 'italic'
        }
    },
    menu: provided => ({ ...provided, zIndex: 9999, color: "#151515" })
};
export const SELECT_JOB_FILTER_STYLE = {
    option: (provided, state) => ({
        ...provided,
        color: "#151515"
    }),
    control: (base, state) => ({
        ...base,
        minHeight: "40px",
        border: 0,
        boxShadow: 0,
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: "rgba(24, 24, 24, 0.2)",
            fontSize: "14px"
        }
    },
    menu: provided => ({ ...provided, zIndex: 9999, color: "#151515" }),
    indicatorSeparator: (styles) => ({display:'none'})
}

export const SELECT_CUSTOM_STYLE = {
    option: (provided, state) => ({
        ...provided,
        color: "#151515"
    }),
    control: (base, state) => ({
        ...base,
        minHeight: "30px",
        borderColor: "rgb(150, 145, 171)",
        '&:hover': {
            border: '1px solid rgb(150, 145, 171) '
        },
        color: "#151515"
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: 'grey',
            fontStyle: 'italic'
        }
    },
    menu: provided => ({ ...provided, zIndex: 9999, color: "#151515" })
};
export const TEXT_LOCAL_BASE_URL = "https://api.textlocal.in/";
export const SMS_API_KEY = "NDczMDQzNDI1NDcxNzg3OTQzNjg3NzQ2NGY3MDYyNzU=";
export const SHOWACTIVITYDATA = 17;
export const OG_TITLE = "Find your dream job in the service-hospitality sector!";
export const OG_IMAGE = "https://www.thejobplus.com/img/tpj-home-promo.png";
export const OG_DESCRIPTION = "India’s first one-stop dedicated platform for Service-Hospitality that allows Employers to connect Job Seekers through a seamless and user-friendly portal.";
export const TJP_FACEBOOK_LINK = "https://www.facebook.com/The-Job-Plus-102185892561845";
export const TJP_LINKEDIN_LINK = "https://www.linkedin.com/company/thejobplus/";
export const TJP_INSTAGRAM_LINK = "https://www.instagram.com/the_jobplus/";
export const TJP_YOUTUBE_LINK = "https://www.youtube.com/channel/UCS8r7q286QjFxEWURf5JBxQ";