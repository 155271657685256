import TJPRolePermission from "assets/Data/TJPRolePermission.json";

export const getPermissionMatrix = async () => {
    localStorage.setItem("userRolePermission", JSON.stringify(TJPRolePermission));
    // const data = JSON.parse(localStorage.getItem("userRolePermission"));
}

export const hasPermission = (resource, action) => {
    let permissionValue = false;
    const userRole = localStorage.getItem("userRole");
    const userRolePermission = JSON.parse(localStorage.getItem("userRolePermission"));
    if (userRolePermission) {
        userRolePermission.roles.forEach((roleItem) => {
            if (roleItem.role_name && userRole) {
                if (roleItem.role_name.toLowerCase() === userRole.toLowerCase()) {
                    permissionValue = roleItem.permission.modules[resource][action];
                }    
            }
        })
    }
    return permissionValue;
}

export const hasRoutePermission = (route) => {
    let permissionValue = false;
    const userRole = localStorage.getItem("userRole");
    const userRolePermission = JSON.parse(localStorage.getItem("userRolePermission"));

    if (userRolePermission) {
        userRolePermission.roles.forEach((roleItem) => {
            if (roleItem.role_name && userRole) {
                if (roleItem.role_name.toLowerCase() === userRole.toLowerCase()) {
                    permissionValue = roleItem.permission.routes.find(routeItem => routeItem === route);
                }
            }
        })
    }
    return permissionValue;
}