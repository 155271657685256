import React from 'react';
import { toast } from 'react-toastify';
import { TOAST_INFO, TOAST_WARN, TOAST_SUCCESS, TOAST_ERROR } from "assets/Constants/Constants";

const infoSvg = <div className="mr-2" style={{display: "inline-block", height:"15px", width: "15px", padding: 0, margin:0}}><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-circle" class="svg-inline--fa fa-exclamation-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"></path></svg></div>
export const ShowAlert = (message, type, options = null) => {
    const localOptions = options ? (options.linkMessage ? options.linkMessage : "") : "";
    if(type === TOAST_INFO){
        toast.info(<div><i className="icon icon-1 fa-check-info text-info mt-1 mr-2"></i><span className="mr-2" >&nbsp;{message} {localOptions}</span>{infoSvg}</div>, { ...options});
    }
    if(type === TOAST_WARN){
        toast.warn(<div><i className="icon icon-1 fa-exclamation-circle text-warning mt-1 mr-2"></i><span className="mr-2" >&nbsp;{message} {localOptions}</span></div>, { ...options});
    }
    if(type === TOAST_SUCCESS){
        toast.success(<div><i className="icon icon-1 fa-check-circle text-success mt-1 mr-2"></i><span className="mr-2" >&nbsp;{message} {localOptions}</span></div>, { ...options})
    }
    if(type === TOAST_ERROR){
        toast.error(<div><i className="icon icon-1 fa-times-circle text-danger mt-1 mr-2"><span className="mr-2" >&nbsp;{message} {localOptions}</span></i></div>, { ...options});
    }
}
// export ShowToast
