import React, { Component } from 'react';
import { hideLoadingScreen } from "components/LoadingScreen";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        };
    }

    componentDidCatch(error, info) {
        console.error("Error Boundary caught an error:", error, info);
        hideLoadingScreen();
        const path = window.location.pathname;
        if (path !== "/") {
            this.setState({
                hasError: true
            });
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className='error-boundry-outer-view'>
                    <div className='img-div'>
                        <img src="/img/oops.jpg" alt="oops" />
                    </div>
                    <div className='header-div'>
                        <h4>OOPS! PAGE NOT FOUND</h4>
                    </div>
                    <div className='sub-header-div'>
                        <p>Sorry, the page you're looking for does'nt exist.</p>
                        <p>Please go back or refresh the page.</p>
                    </div>
                </div>
            )
        }
        else {
            return this.props.children;
        }
    }
}

export default ErrorBoundary