import React from "react";
import { withRouter, Switch, Redirect } from "react-router-dom";
import queryString from 'query-string';
import Cookies from 'universal-cookie';

import AdminRoutes from "components/RoutesComponent/AdminRoutes";
import CandidateRoutes from "components/RoutesComponent/CandidateRoutes";
import RecruiterRoutes from "components/RoutesComponent/RecruiterRoutes";
import GenericRoutes from "components/RoutesComponent/GenericRoutes";
import PublicRoutes from "components/RoutesComponent/PublicRoutes";
import TJPRoutes from "assets/Data/TJPRoutes";

// Public Pages
// const HomePage = React.lazy(() => import('views/HomePage'));
const AboutUsPage = React.lazy(() => import("views/AboutUs/AboutUsPage"));
const PrivacyPolicy = React.lazy(() => import("views/Login/PrivacyPolicy"));
const TermsofUse  = React.lazy(() => import("views/Login/TermsofUse"));
const ForgotPasswordPage = React.lazy(() => import("views/Login/ForgotPasswordPage"));
const ContactUsPage = React.lazy(() => import("views/ContactUs/ContactUsPage"));
const FAQPage = React.lazy(() => import("views/FAQ/FAQPage"));
const SocialSharePage = React.lazy(() => import("views/Jobs/ShareSocialPage"));
const VerifyPhoneNumber = React.lazy(() => import("views/Login/VerifyPhoneNumber"));
const ConfirmSignup = React.lazy(() => import('views/Login/ConfirmSignup'));
const JobSearchPage = React.lazy(() => import('views/Jobs/JobModels/JobSearchPage'));
const BlogListPage = React.lazy(() => import("views/Blog/BlogListPage"));
const BlogDetailsPage = React.lazy(() => import("views/Blog/BlogDetailsPage"));
const CreateBlogPage = React.lazy(() => import("views/Blog/CreateBlogPage"));
const LocationLandingPage = React.lazy(() => import('views/LandingPages/Location/LocationLandingPage'));
const LocationJobListPage = React.lazy(() => import("views/LandingPages/Location/LocationJobListPage"));
const JobTitleLandingPage = React.lazy(() => import('views/LandingPages/JobTitle/JobTitleLandingPage'));
const JobTitleListPage = React.lazy(() => import("views/LandingPages/JobTitle/JobTitleListPage"));
const JobCategoryLandingPage = React.lazy(() => import("views/LandingPages/JobCategory/JobCategoryLandingPage"));
const JobCategoryListPage = React.lazy(() => import("views/LandingPages/JobCategory/JobCategoryListPage"));
const JobBrandLandingPage = React.lazy(() => import("views/LandingPages/Brands/JobBrandLandingPage"));
const JobBrandListPage = React.lazy(() => import("views/LandingPages/Brands/JobBrandListPage"));
const LoginPage = React.lazy(() => import("views/Login/LoginPage"));
const RegistrationPage = React.lazy(() => import("views/Login/RegistrationPage"));

const EazyDinerLandingPage = React.lazy(() => import("views/JobProvider/EazyDinerLandingPage"));
const OrchidDubaiLandingPage = React.lazy(() => import("views/JobProvider/OrchidDubaiLandingPage"));
const BlogUnsubscribePage = React.lazy(() => import("views/Blog/BlogUnsubscribePage"));
// const PlusInitiativePage = React.lazy(() => import("views/ThePlusInitiative/PlusInitiativePage"));
const EdmApproveRejectPage = React.lazy(() => import("views/EmailEdm/EdmApproveRejectPage"));
const ThankYouPage= React.lazy(()=> import("views/ThankYou/ThankYouPage"));
// Generic Pages
const ResumePage = React.lazy(() => import("views/JobSeeker/ResumePage"));
const JobDetailsPage = React.lazy(() => import("views/Jobs/JobDetailsPage"));
const CompaniesPage = React.lazy(() => import("views/JobProvider/CompaniesPage"));
const JobProviderJobs = React.lazy(() => import("views/Jobs/JobModels/JobProviderJobs"));
const ActivityTrackingPage = React.lazy(() => import("views/Activity/ActivityTrackingPage"));

// Candidate Pages
const AllJobs = React.lazy(() => import("views/Jobs/JobModels/AllJobs"));
const NearByJobs = React.lazy(() => import("views/Jobs/JobMap/NearByJobs"));
const CompaniesListPage = React.lazy(() => import("views/JobProvider/CompaniesListPage"));
const JobSeekerApplications = React.lazy(() => import("views/Jobs/JobModels/JobApplicationPage"));
const JobShortlistPage = React.lazy(() => import("views/Jobs/JobModels/JobShortlistPage"));
const SubmitResumePage = React.lazy(() => import("views/JobSeeker/SubmitResumePage"));

// Recruiter Pages
const JobPostPage = React.lazy(() => import("views/JobProvider/JobPostPage"));
const CandidateListPage = React.lazy(() => import("views/JobSeeker/CandidateListPage"));
const CreateCompanyProfilePage = React.lazy(() => import("views/JobProvider/CreateCompanyProfilePage"));
const ArchiveJobList = React.lazy(() => import("views/JobProvider/ArchiveJobList"));
const EmployerHomePage = React.lazy(() => import("views/EmployerHomePage"));
const DashboardPage = React.lazy(() => import('views/JobProvider/DashboardPage'));

// Admin Pages
const ReasonReject = React.lazy(() => import("views/JobProvider/ReasonReject"));
const InviteAssesment = React.lazy(() => import("views/SHL/InviteAssesment"));
const ImportTestSkills  = React.lazy(() => import("views/SHL/ImportSkills"));
const CreateJobProviderPage = React.lazy(() => import("views/JobProvider/CreateJobProviderPage.js"));
const CreateMetaDataPage = React.lazy(() => import("views/Metadata/CreateMetaDataPage.js"));
const AdminJobListPage = React.lazy(() => import('views/AdminPanel/AdminJobListPage'));
const AdminCandidateListPage = React.lazy(() => import('views/AdminPanel/AdminCandidateListPage'));
const AdminEmployerListPage = React.lazy(() => import('views/AdminPanel/AdminEmployerListPage'));
const AdminDashboardPage = React.lazy(() => import('views/AdminPanel/AdminDashboardPage'));
const AdminCandidateDashboardPage = React.lazy(() => import('views/AdminPanel/AdminCandidateDashboardPage'));
const AdminRoles = React.lazy(() => import("views/AdminPanel/RolesPage"));
const AdminUsers = React.lazy(() => import("views/AdminPanel/UsersPage"));
const CreateUser = React.lazy(()=> import("views/AdminPanel/CreateUserPage"));
const LoginUser = React.lazy(()=> import("views/AdminPanel/LoginUsers"));
const PlusEnquiry= React.lazy(()=> import("views/AdminPanel/PlusEnquiry"));
const EmailEdmPage= React.lazy(()=> import("views/EmailEdm/EmailEdmPage")); 
const EdmEmailListPage= React.lazy(()=> import("views/EmailEdm/EdmEmailListPage"));
const EmailTemplateListPage= React.lazy(()=> import("views/EmailTemplate/EmailTemplateListPage"));
const CreateEmailTemplatePage= React.lazy(()=> import("views/EmailTemplate/CreateEmailTemplatePage"));

const cookies = new Cookies();

export const Routes = withRouter((props) => {
    let urlString = window.location.href;
    urlString =urlString.replaceAll("#","~~~");
    const queryParams = queryString.parseUrl(urlString, { parseFragmentIdentifier: true});
    if(queryParams.query && Object.keys(queryParams.query).length !== 0){
        let sourceParamsObj = {}
        Object.keys(queryParams.query).forEach((key, index) => {
            queryParams.query[key] = queryParams.query[key].replaceAll("~~~","#");;
            sourceParamsObj[key] = queryParams.query[key];
        });
        const sourceParams = {};
        if (sourceParamsObj["utm_source"] || sourceParamsObj["fbclid"]) {
            sourceParams.seeker_source = sourceParamsObj.utm_source ? sourceParamsObj.utm_source : "unknown";
            sourceParams.medium = sourceParamsObj.utm_medium ? sourceParamsObj.utm_medium : "unknown";
            sourceParams.campaign = sourceParamsObj.utm_campaign ? sourceParamsObj.utm_campaign : "unknown";
            sourceParams.fbclid = sourceParamsObj.fbclid ? sourceParamsObj.fbclid : "unknown";
            sourceParams.utm_term = sourceParamsObj.utm_term ? sourceParamsObj.utm_term : "unknown"
            cookies.set('sourceParams', sourceParams);
        }
    }

    const sessionUserData = JSON.parse(localStorage.getItem("userDetails"));
    const isCandidateProfile = sessionUserData ? (sessionUserData.profile === "employee" ? true : false) : false;
    const isEmployerProfile = sessionUserData ? (sessionUserData.profile === "employer" ? true : false) : false;
    const isAdminProfile = sessionUserData ? ((sessionUserData.profile === "admin" || sessionUserData.profile === "tjp_user") ? true : false) : false;
    
    let routePath = "/pages/home.html";
    if (!sessionUserData) {
        routePath = "/pages/home.html";
    }
    else {
        if (isCandidateProfile) {
            routePath = "/pages/home.html";
        }
        else if (isEmployerProfile) {
            routePath = "/employer-home-page";
        }
        else if (isAdminProfile) {
            if (sessionUserData?.role_name) {
                if (sessionUserData.role_name === "admin") {
                    routePath = "/admin-employer-dashboard";
                }
                else if (sessionUserData.role_name === "job creator") {
                    routePath = "/admin-job-list-page";
                }
                else {
                    routePath = "/blogs";
                }
            }
        }
    }

    const pathname = window.location.pathname;
    const isAllowed = TJPRoutes.some(route => {
        const pathParts = pathname.split('/');
        const routeParts = route.split('/');
        if (pathParts.length !== routeParts.length) {
            return false;
        }
        for (let i = 0; i < routeParts.length; i++) {
            if (routeParts[i].startsWith(':')) {
                continue;
            }
            if (pathParts[i] !== routeParts[i]) {
                return false;
            }
        }
        return true;
    });
    if (!isAllowed) {
        window.location.href = routePath;
    }

    return(
        <Switch>
            {/* Public Routes */}
            {/* <PublicRoutes component={ HomePage } path="/" exact /> */}
            <PublicRoutes component={ LoginPage } path="/login" exact />
            <PublicRoutes component={ RegistrationPage } path="/sign-up" exact />
            <PublicRoutes component={ AboutUsPage } path="/about-us-page" exact />
            <PublicRoutes component={ PrivacyPolicy } path="/privacy-policy" exact />
            <PublicRoutes component={ ForgotPasswordPage } path="/forgot-password-page" exact />
            <PublicRoutes component={ TermsofUse } path="/terms-of-use" exact />
            <PublicRoutes component={ ContactUsPage } path="/contact-us-page" exact />
            <PublicRoutes component={ FAQPage } path="/faq-page" exact />
            <PublicRoutes component={ SocialSharePage } path="/social-page/:shareId" exact />
            <PublicRoutes component={ VerifyPhoneNumber } path="/verify-phone-number" exact />
            <PublicRoutes component={ ConfirmSignup } path="/confirm-signup-page" exact />
            <PublicRoutes component={ JobSearchPage } path="/search-jobs" exact />
            <PublicRoutes component={ CreateJobProviderPage } path="/create-job-provider-page" exact />
            <PublicRoutes component={ AllJobs } path="/all-jobs" exact />
            <PublicRoutes component={ BlogListPage } path="/blogs" exact />
            <PublicRoutes component={ BlogDetailsPage } path="/blogs/:blog_title" exact />
            <PublicRoutes component={ LocationLandingPage } path="/location" exact />
            <PublicRoutes component={ LocationJobListPage } path="/location/:city" exact />
            <PublicRoutes component={ JobTitleLandingPage } path="/jobtitle" exact />
            <PublicRoutes component={ JobTitleListPage } path="/jobtitle/:title" exact />
            <PublicRoutes component={ JobCategoryLandingPage } path="/category" exact />
            <PublicRoutes component={ JobCategoryListPage } path="/category/:category" exact />
            <PublicRoutes component={ JobBrandLandingPage } path="/brands" exact />
            <PublicRoutes component={ JobBrandListPage } path="/brands/:brands" exact />
            <PublicRoutes component={ JobDetailsPage } path="/job/:jobLink" exact />
            <PublicRoutes component={ EazyDinerLandingPage } path="/eazy-diner" exact />
            <PublicRoutes component={ OrchidDubaiLandingPage } path="/royal-orchid-dubai" exact />
            <PublicRoutes component={ LoginUser } path="/login-tjp-user" exact />
            <PublicRoutes component={ BlogUnsubscribePage } path="/blog/unsubscribe/:email_id" exact />
            {/* <PublicRoutes component={ PlusInitiativePage } path="/the-plus-initiative" exact /> */}
            <PublicRoutes component={ ThankYouPage } path="/thank-you-page" exact />
            <PublicRoutes component={ EdmApproveRejectPage } path="/edm/:email_edm_id/:approve/:email_id" exact />

            {/* Generic Pages */}
            <GenericRoutes component={ ResumePage } path="/candidate/:candidateLink" exact />
            <GenericRoutes component={ CompaniesPage } path="/companies-page" exact />
            <GenericRoutes component={ JobProviderJobs } path="/jobprovider-jobs" exact />
            <GenericRoutes component={ ActivityTrackingPage } path="/activity-page" exact />

            {/* Candidate Routes */}
            <CandidateRoutes component={ NearByJobs } path="/near-by-jobs" exact />
            <CandidateRoutes component={ CompaniesListPage } path="/companies-list-page" exact />
            <CandidateRoutes component={ JobSeekerApplications } path="/jobseeker-applications" exact />
            <CandidateRoutes component={ JobShortlistPage } path="/shortlisted-jobs" exact />
            <CandidateRoutes component={ SubmitResumePage } path="/submit-resume-page" exact />

            {/* Recruiter Routes */}
            <RecruiterRoutes component={ JobPostPage } path="/job-post-page/:postFlag" exact />
            <RecruiterRoutes component={ CandidateListPage } path="/candidate-list-page/:flag"/>
            <RecruiterRoutes component={ CreateCompanyProfilePage } path="/create-company-profile-page" exact />
            <RecruiterRoutes component={ ArchiveJobList } path="/archive-job-list" exact />
            <RecruiterRoutes component={ EmployerHomePage } path="/employer-home-page" exact />
            <RecruiterRoutes component={ DashboardPage } path="/dashboard" exact />

            {/* Admin Routes */}
            <AdminRoutes component={ ReasonReject } path="/employer-reject-reason" exact />
            <AdminRoutes component={ InviteAssesment } path="/shl-invite-assesment" exact />
            <AdminRoutes component={ ImportTestSkills } path="/shl-skill-import" exact />
            <AdminRoutes component={ CreateMetaDataPage } path="/create-metadata-page" exact />
            <AdminRoutes component={ AdminJobListPage } path="/admin-job-list-page" exact />
            <AdminRoutes component={ AdminCandidateListPage } path="/admin-candidate-list-page" exact /> 
            <AdminRoutes component={ AdminEmployerListPage } path="/admin-employer-list-page" exact />
            <AdminRoutes component={ CreateBlogPage } path="/create-blog" exact />
            <AdminRoutes component={ AdminDashboardPage } path="/admin-employer-dashboard" exact />
            <AdminRoutes component={ AdminCandidateDashboardPage } path="/admin-candidate-dashboard" exact />
            <AdminRoutes component={ AdminRoles } path="/users-role" exact />
            <AdminRoutes component={ AdminUsers } path="/admin-user-list" exact />
            <AdminRoutes component={ CreateUser } path="/create-admin-user" exact />
            <AdminRoutes component={ EmailEdmPage } path="/create-email-edm" exact />
            <AdminRoutes component={ EdmEmailListPage } path="/email-edm-list" exact />
            <AdminRoutes component={ EmailTemplateListPage } path="/email-template-list" exact />
            <AdminRoutes component={ CreateEmailTemplatePage } path="/create-email-template" exact />
            <AdminRoutes component={ PlusEnquiry } path="/plus-initiative-enquiry" exact />

            <Redirect
                to={{
                    pathname: "/",
                    state: {
                        from: props.location
                    }
                }}
            />
        </Switch>
    )
});
