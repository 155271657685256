const element = document.querySelector(".preloader");

export const showLoadingScreen = () => {
    if(element !== null){
        element.classList.remove("loaded");
    }
}

export const hideLoadingScreen = () => {
    const customEvent = new CustomEvent('InitUIComponent', { });
    document.dispatchEvent(customEvent);
}