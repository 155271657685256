import React from 'react'
import { ToastContainer } from 'react-toastify';

function TostComponent() {
    return (
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={true} newestOnTop={false} closeOnClick  pauseOnFocusLoss draggable pauseOnHover/>
    )
}

export default TostComponent
